import { createApi } from '@reduxjs/toolkit/query/react';
import { createCustomFetchBaseQuery } from '../baseQuery';

export const userProfileApi = createApi({
  reducerPath: 'profile',
  baseQuery: createCustomFetchBaseQuery(),
  tagTypes: ['User_Profile'],
  endpoints: (builder) => ({
    getConnections: builder.query({
      query: ({ page, limit, id }) =>
        `connections/mutualConnections?page=${page}&limit=${limit}&userId=${id}`,
      transformResponse: (res) => res,
      providesTags: ['User_Profile'],
    }),

    getMyLikes: builder.query({
      query: ({ page, limit, id }) => `connections/likes?page=${page}&limit=${limit}&userId=${id}`,
      transformResponse: (res) => res,
      providesTags: ['User_Profile'],
    }),

    getLikedMe: builder.query({
      query: ({ page, limit, id }) =>
        `connections/likedBy?page=${page}&limit=${limit}&userId=${id}`,
      transformResponse: (res) => res,
      providesTags: ['User_Profile'],
    }),

    getPassed: builder.query({
      query: ({ page, limit, id }) =>
        `connections/dislikes?page=${page}&limit=${limit}&userId=${id}`,
      transformResponse: (res) => res,
      providesTags: ['User_Profile'],
    }),

    getFollowings: builder.query({
      query: ({ page, limit, id }) => `user/${id}/followings?page=${page}&limit=${limit}`,
      transformResponse: (res) => res,
      providesTags: ['User_Profile'],
    }),

    getFollowers: builder.query({
      query: ({ page, limit, id }) => `user/${id}/followers?page=${page}&limit=${limit}`,
      transformResponse: (res) => res,
      providesTags: ['User_Profile'],
    }),

    getJourneys: builder.query({
      query: ({ page, limit, id }) => `journey/user/${id}?page=${page}&limit=${limit}`,
      transformResponse: (res) => res,
      providesTags: ['User_Profile'],
    }),

    getJournals: builder.query({
      query: ({ page, limit, id }) => `journal/user/${id}?page=${page}&limit=${limit}`,
      transformResponse: (res) => res,
      providesTags: ['User_Profile'],
    }),

    getUserProfile: builder.query({
      query: ({ id }) => `user/${id}/profile`,
      transformResponse: (res) => res?.data,
      providesTags: ['User_Profile'],
    }),

    getUserFeelings: builder.query({
      query: ({ id, date }) => `userFeeling/${id}?date=${date}`,
      transformResponse: (res) => {
        return res; // Return the response unchanged
      },
      providesTags: ['User_Profile'],
    }),
  }),
});

export const {
  useGetConnectionsQuery,
  useGetLikedMeQuery,
  useGetMyLikesQuery,
  useGetPassedQuery,
  useGetFollowingsQuery,
  useGetFollowersQuery,
  useGetJournalsQuery,
  useGetJourneysQuery,
  useGetUserProfileQuery,
  useGetUserFeelingsQuery
} = userProfileApi;
