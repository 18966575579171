import { createApi } from '@reduxjs/toolkit/query/react';
import { createCustomFetchBaseQuery } from '../baseQuery';

export const pregnancyJourneyApi = createApi({
  reducerPath: 'pregnancyJourney',
  baseQuery: createCustomFetchBaseQuery(),
  tagTypes:['PregnancyJourney'],
  endpoints: (builder) => ({
    getAllPregnanceJourney: builder.query({
      query: () => 'accountSetup/pregnancyTypes',
      transformResponse: (res) => res.data,
      providesTags:['PregnancyJourney']
    }),
    addNewPregnanceJourney: builder.mutation({
      query: (data) => ({
        url: `accountSetup/pregnancyTypes`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: data,
      }),
      invalidatesTags:['PregnancyJourney']
    }),
    updatePregnanceJourney: builder.mutation({
      query: ({ id, data }) => ({
        url: `accountSetup/pregnancyTypes/${id}`,
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: data,
      }),
      invalidatesTags:['PregnancyJourney']
    }),

    deletePregnanceJourney: builder.mutation({
      query: (id) => ({
        url: `accountSetup/pregnancyTypes/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags:['PregnancyJourney']
    }),
  }),
});

export const {
  useGetAllPregnanceJourneyQuery,
  useAddNewPregnanceJourneyMutation,
  useUpdatePregnanceJourneyMutation,
  useDeletePregnanceJourneyMutation,
} = pregnancyJourneyApi;
