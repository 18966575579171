import { createApi } from '@reduxjs/toolkit/query/react';
import { createCustomFetchBaseQuery } from '../baseQuery';

export const adminApi = createApi({
  reducerPath: 'dashboard',
  baseQuery: createCustomFetchBaseQuery(),
  tagTypes: ['Dashboard'],
  endpoints: (builder) => ({
    getDashboardStats: builder.query({
      query: () => 'admin/dashboard',
      transformResponse: (res) => res.data,
      providesTags: ['Dashboard'],
    }),

    getUserStatsByRegion: builder.query({
      query: ({ year }) => `admin/getUserStatsByRegion?year=${year}`,
      transformResponse: (res) => res.data,
      providesTags: ['Dashboard'],
    }), 
    getDropdownStats: builder.query({
      query: () => 'admin/journeyStats',
      transformResponse: (res) => res.data,
      providesTags: ['Dashboard'],
    }),
  }),
});

export const { useGetDashboardStatsQuery, useGetDropdownStatsQuery, useGetUserStatsByRegionQuery } =
  adminApi;
