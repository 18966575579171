import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { productsApi } from './Reducer/products';
import { authApi } from './Reducer/auth';
import userReducer from './slices/userSlice';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { pregnancyJourneyApi } from './Reducer/pregnancy-journey';
import { conceptionMethodApi } from './Reducer/conception-method';
import { pregnancyLossTypesApi } from './Reducer/pregnancy-loss-types';
import { pregnancyWeekApi } from './Reducer/weeks-pregnancy';
import { lossYearRangeApi } from './Reducer/loss-year-range';
import { lossNumberApi } from './Reducer/loss-number';
import { emotionalStatusApi } from './Reducer/emotional-status';
import { healingPhaseApi } from './Reducer/healing-phase';
import { planingStatusAPI } from './Reducer/planing-status';
import { additionalSupportApi } from './Reducer/additional-support';
import { settingsApi } from './Reducer/settings';
import { supportApi } from './Reducer/support';
import { feelingApi } from './Reducer/feelings';
import { adminApi } from './Reducer/dashboard';
import { usersApi } from './Reducer/users';
import { chatApi } from './Reducer/chat';
import { userProfileApi } from './Reducer/user-profile';

// Define the persist configuration
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user'],
};

// Create a persisted reducer for the user slice
const persistedUserReducer = persistReducer(persistConfig, userReducer);

// Create the Redux store with the persisted reducer for the user slice
export const store = configureStore({
  reducer: {
    [productsApi.reducerPath]: productsApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [pregnancyJourneyApi.reducerPath]: pregnancyJourneyApi.reducer,
    [conceptionMethodApi.reducerPath]: conceptionMethodApi.reducer,
    [pregnancyLossTypesApi.reducerPath]: pregnancyLossTypesApi.reducer,
    [pregnancyWeekApi.reducerPath]: pregnancyWeekApi.reducer,
    [lossYearRangeApi.reducerPath]: lossYearRangeApi.reducer,
    [lossNumberApi.reducerPath]:lossNumberApi.reducer,
    [emotionalStatusApi.reducerPath]: emotionalStatusApi.reducer,
    [healingPhaseApi.reducerPath]: healingPhaseApi.reducer,
    [planingStatusAPI.reducerPath]: planingStatusAPI.reducer,
    [additionalSupportApi.reducerPath]: additionalSupportApi.reducer,
    [settingsApi.reducerPath]: settingsApi.reducer,
    [supportApi.reducerPath]: supportApi.reducer,
    [feelingApi.reducerPath]: feelingApi.reducer,
    [adminApi.reducerPath]: adminApi.reducer,
    [usersApi.reducerPath]:usersApi.reducer,
    [chatApi.reducerPath]: chatApi.reducer, 
    [userProfileApi.reducerPath]: userProfileApi.reducer,
    user: persistedUserReducer, // Use the persisted user reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      productsApi.middleware,
      authApi.middleware,
      pregnancyJourneyApi.middleware,
      conceptionMethodApi.middleware,
      pregnancyLossTypesApi.middleware,
      pregnancyWeekApi.middleware,
      lossYearRangeApi.middleware,
      lossNumberApi.middleware,
      emotionalStatusApi.middleware,
      healingPhaseApi.middleware,
      planingStatusAPI.middleware,
      additionalSupportApi.middleware,
      settingsApi.middleware,
      supportApi.middleware,
      feelingApi.middleware,
      adminApi.middleware,
      usersApi.middleware,
      chatApi.middleware,
      userProfileApi.middleware,  
    ),
});

// Initialize Redux Persist
export const persistor = persistStore(store);

// Setup listeners
setupListeners(store.dispatch);
