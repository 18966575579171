import { createApi } from '@reduxjs/toolkit/query/react';
import { createCustomFetchBaseQuery } from '../baseQuery';

export const pregnancyLossTypesApi = createApi({
  reducerPath: 'pregnancyLossType',
  baseQuery: createCustomFetchBaseQuery(),
  tagTypes: ['PregnancyLoss'],
  endpoints: (builder) => ({
    getAllPregnancyLossTypes: builder.query({
      query: () => 'accountSetup/pregnancyLossTypes',
      transformResponse: (res) => res.data,
      providesTags: ['PregnancyLoss'], // Use providesTags here
    }),
    addNewPregnancyLossTypes: builder.mutation({
      query: (data) => ({
        url: 'accountSetup/pregnancyLossTypes',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: data,
      }),
      invalidatesTags: ['PregnancyLoss'], // This will trigger getAllPregnancyLossTypes to refetch
    }),
    updatePregnancyLossTypes: builder.mutation({
      query: ({ id, data }) => ({
        url: `accountSetup/pregnancyLossTypes/${id}`,
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: data,
      }),
      invalidatesTags: ['PregnancyLoss'],
    }),
    deletePregnancyLossTypes: builder.mutation({
      query: (id) => ({
        url: `accountSetup/pregnancyLossTypes/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['PregnancyLoss'],
    }),
  }),
});


export const {
  useGetAllPregnancyLossTypesQuery,
  useAddNewPregnancyLossTypesMutation,
  useUpdatePregnancyLossTypesMutation,
  useDeletePregnancyLossTypesMutation,
} = pregnancyLossTypesApi;