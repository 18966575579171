import { createApi } from '@reduxjs/toolkit/query/react';
import { createCustomFetchBaseQuery } from '../baseQuery';

export const feelingApi = createApi({
  reducerPath: 'feeling',
  baseQuery: createCustomFetchBaseQuery(),
  tagTypes: ['Feeling'],
  endpoints: (builder) => ({
    getAllFeelings: builder.query({
      query: () => 'feelings',
      transformResponse: (res) => res.data,
      providesTags: ['Feeling']
    }),
    addNewFeeling: builder.mutation({
      query: (data) => ({
        url: `feelings`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: data,
      }),
      invalidatesTags: ['Feeling']
    }),
    updateFeeling: builder.mutation({
      query: ({ id, data }) => ({
        url: `feelings/${id}`,
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: data,
      }),
      invalidatesTags: ['Feeling']
    }),
    deleteFeeling: builder.mutation({
      query: (id) => ({
        url: `feelings/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Feeling']
    }),
  }),
});

export const {
  useGetAllFeelingsQuery,
  useAddNewFeelingMutation,
  useUpdateFeelingMutation,
  useDeleteFeelingMutation,
} = feelingApi;
