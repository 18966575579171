import { createApi } from '@reduxjs/toolkit/query/react';
import { createCustomFetchBaseQuery } from '../baseQuery';

export const chatApi = createApi({
  reducerPath: 'chats',
  baseQuery: createCustomFetchBaseQuery(),
  tagTypes: ['Chat'],
  endpoints: (builder) => ({
    getChats: builder.query({
      query: ({ page, limit, keyword }) =>
        `conversation/chats?page=${page}&limit=${limit}&keyword=${keyword}`,
      transformResponse: (res) => res,
      providesTags: ['Chat'],
    }),
    getMessages: builder.query({
      query: ({ id, page, limit }) => {
        if (!id) return null;
        const url = `conversation/${id}/messages${page && limit ? `?page=${page}&limit=${limit}` : ''}`;
        return url;
      },
      transformResponse: (res) => res,
      // providesTags: ['Chat'],
    }),

    sendMessage: builder.mutation({
      query: ({ id, data }) => {
        return {
          url: `conversation/${id}/message`,
          method: 'POST',
          body: data,
        };
      },
      invalidatesTags: ['Chat'],
    }),
  }),
});

export const { useGetChatsQuery, useGetMessagesQuery, useSendMessageMutation } = chatApi;
