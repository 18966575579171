import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { enqueueSnackbar } from 'notistack';

export const createCustomFetchBaseQuery = () => {
  const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const user = getState()?.user?.user;

      const token = user?.token || '';

      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }

      return headers;
    },
  });

  // Return the custom base query with additional 401 handling logic

  return async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);

    if (result?.error) {
      enqueueSnackbar(result?.error?.data?.message || 'Something went wrong', {
        variant: 'error',
      });
    }

    // if (result.error && result.error.status === 401) {
    //   // storage.clear()
    //   localStorage.clear()
    //   window.location.replace('/auth/jwt/login')
    // }

    return result;
  };
};
