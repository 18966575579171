import { createApi } from '@reduxjs/toolkit/query/react';
import { createCustomFetchBaseQuery } from '../baseQuery';

export const supportApi = createApi({
  reducerPath: 'support',
  baseQuery: createCustomFetchBaseQuery(),
  tagTypes: ['Contact-Us'],
  endpoints: (builder) => ({
    getAllSupport: builder.query({
      query: ({ page, limit, keyword, status }) => {
        let url = `/support?page=${page + 1}&limit=${limit}`;

        if (keyword) {
          url += `&keyword=${keyword}`;
        }

        if (status) {
          url += `&status=${status}`;
        }

        return url;
      },
      transformResponse: (res) => res,
      providesTags: ['Contact-Us'],
    }),

    getAllContactUs: builder.query({
      query: ({ page, limit, keyword, status }) => {
        // Start with base URL and add page & limit parameters

      
        let url = `/contactUs?page=${page + 1}&limit=${limit}`;

        if (keyword) {
          url += `&keyword=${keyword}`;
        }

        if (status) {
          url += `&status=${status}`;
        }

        return url;
      },
      transformResponse: (res) => res,
      providesTags: ['Contact-Us'],
    }),

    sendReply: builder.mutation({
      query: (data) => ({
        url: `support/reply`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: data,
      }),
      invalidatesTags: ['Contact-Us'],
    }),

    updateContactStatus: builder.mutation({
      query: ({ id, data }) => ({
        url: `contactUs/${id}`,
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: data,
      }),
      invalidatesTags: ['Contact-Us'],
    }),

    updateSupportStatus: builder.mutation({
      query: ({ id, data }) => ({
        url: `support/${id}`,
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: data,
      }),
      invalidatesTags: ['Contact-Us'],
    }),
  }),
});

export const {
  useGetAllSupportQuery,
  useGetAllContactUsQuery,
  useSendReplyMutation,
  useUpdateContactStatusMutation,
  useUpdateSupportStatusMutation,
} = supportApi;
