import { createApi } from '@reduxjs/toolkit/query/react';
import { createCustomFetchBaseQuery } from '../baseQuery';

export const lossYearRangeApi = createApi({
  reducerPath: 'lossYearRange',
  baseQuery: createCustomFetchBaseQuery(),
  tagTypes: ['LossYearRange'],
  endpoints: (builder) => ({
    getAllLossYearRange: builder.query({
      query: () => 'accountSetup/yearOfLossRanges',
      transformResponse: (res) => res.data,
      providesTags: ['LossYearRange'],
    }),
    addNewLossYearRange: builder.mutation({
      query: (data) => ({
        url: `accountSetup/yearOfLossRanges`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: data,
      }),
      invalidatesTags: ['LossYearRange'],
    }),
    updateLossYearRange: builder.mutation({
      query: ({ id, data }) => ({
        url: `accountSetup/yearOfLossRanges/${id}`,
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: data,
      }),
      invalidatesTags: ['LossYearRange'],
    }),

    deleteLossYearRange: builder.mutation({
      query: (id) => ({
        url: `accountSetup/yearOfLossRanges/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['LossYearRange'],
    }),
  }),
});

export const {
  useGetAllLossYearRangeQuery,
  useAddNewLossYearRangeMutation,
  useUpdateLossYearRangeMutation,
  useDeleteLossYearRangeMutation,
} = lossYearRangeApi;
