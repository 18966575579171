import { createApi } from '@reduxjs/toolkit/query/react';
import { createCustomFetchBaseQuery } from '../baseQuery';

export const settingsApi = createApi({
  reducerPath: 'settings',
  baseQuery: createCustomFetchBaseQuery(),
  tagTypes: ['AdminSettings'],
  endpoints: (builder) => ({
    getPrivacy: builder.query({
      query: () => 'settings/privacyPolicy',
      transformResponse: (res) => res.data,
      providesTags: ['AdminSettings'],
    }),
    getTac: builder.query({
        query: () => 'settings/termsAndConditions',
        transformResponse: (res) => res.data,
        providesTags: ['AdminSettings'],
      }),
    addAdminSetting: builder.mutation({
      query: (data) => ({
        url: `settings/create`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: data,
      }),
      invalidatesTags: ['AdminSettings'],
    }),

    updateAdminSetting: builder.mutation({
      query: ({ id, data }) => ({
        url: `settings/update/${id}`,
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: data,
      }),
      invalidatesTags: ['AdminSettings'],
    }),

    getSubscriptionStatus: builder.query({
      query: () => 'settings/subscriptionStatus',
      transformResponse: (res) => res.data,
      providesTags: ['AdminSettings'],
    }),
  }),
});

export const {
  useGetPrivacyQuery,
  useGetTacQuery,
  useUpdateAdminSettingMutation,
  useAddAdminSettingMutation,
  useGetSubscriptionStatusQuery
} = settingsApi;
