// redux/userSlice.js
import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
  name: 'user', // The name of the slice
  initialState: {
    user: null,
    pagination: {
      user: 10,
      contact: 10,
      support: 10,
    },
  },
  reducers: {
    setUser: (state, action) => {
      let user = { ...action.payload };
      user.displayName = `${user?.basicInfo?.name}`;
      state.user = user;
    }, // Action to set the user in the state
    logout: (state) => {
      localStorage.clear();
      state.user = null;
    },

    setPagination: (state, action) => {
      state.pagination = { ...action.payload };
    },
  },
});

// Exporting actions
export const { setUser, logout ,setPagination } = userSlice.actions;
export const selectUser = (state) => state.user.user;
export const selectPagination = (state) => state.user.pagination;
// Exporting the reducer
export default userSlice.reducer;
