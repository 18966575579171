import { createApi } from '@reduxjs/toolkit/query/react';
import { createCustomFetchBaseQuery } from '../baseQuery';

export const healingPhaseApi = createApi({
  reducerPath: 'healingPhase',
  baseQuery: createCustomFetchBaseQuery(),
  tagTypes:['CurrentHealingPhase'],
  endpoints: (builder) => ({
    getAllHealingPhase: builder.query({
      query: () => 'accountSetup/healingPhases',
      transformResponse: (res) => res.data,
      providesTags:['CurrentHealingPhase']
    }),
    addNewHealingPhase: builder.mutation({
      query: (data) => ({
        url: `accountSetup/healingPhases`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: data,
      }),
      invalidatesTags:['CurrentHealingPhase']
    }),
    updateHealingPhase: builder.mutation({
      query: ({ id, data }) => ({
        url: `accountSetup/healingPhases/${id}`,
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: data,
      }),
      invalidatesTags:['CurrentHealingPhase']
    }),

    deleteHealingPhase: builder.mutation({
      query: (id) => ({
        url: `accountSetup/healingPhases/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags:['CurrentHealingPhase']
    }),
  }),
});

export const {
  useGetAllHealingPhaseQuery,
  useAddNewHealingPhaseMutation,
  useUpdateHealingPhaseMutation,
  useDeleteHealingPhaseMutation,
} = healingPhaseApi;
