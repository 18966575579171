import { createApi } from '@reduxjs/toolkit/query/react';
import { createCustomFetchBaseQuery } from '../baseQuery';

export const emotionalStatusApi = createApi({
  reducerPath: 'emotionalStatus',
  baseQuery: createCustomFetchBaseQuery(),
  tagTypes:['EmotionalStatuses'],
  endpoints: (builder) => ({
    getAllEmotionalStatus: builder.query({
      query: () => 'accountSetup/emotionalStatuses',
      transformResponse: (res) => res.data,
      providesTags:['EmotionalStatuses']
    }),
    addNewEmotionalStatus: builder.mutation({
      query: (data) => ({
        url: `accountSetup/emotionalStatuses`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: data,
      }),
      invalidatesTags:['EmotionalStatuses']
    }),
    updateEmotionalStatus: builder.mutation({
      query: ({ id, data }) => ({
        url: `accountSetup/emotionalStatuses/${id}`,
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: data,
      }),
      invalidatesTags:['EmotionalStatuses']
    }),

    deleteEmotionalStatus: builder.mutation({
      query: (id) => ({
        url: `accountSetup/emotionalStatuses/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags:['EmotionalStatuses']
    }),
  }),
});

export const {
    useGetAllEmotionalStatusQuery,
    useAddNewEmotionalStatusMutation,
    useUpdateEmotionalStatusMutation,
    useDeleteEmotionalStatusMutation,
} = emotionalStatusApi;
