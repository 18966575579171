import { createApi } from '@reduxjs/toolkit/query/react';
import { createCustomFetchBaseQuery } from '../baseQuery';

export const planingStatusAPI = createApi({
  reducerPath: 'planingStatus',
  baseQuery: createCustomFetchBaseQuery(),
  tagTypes: ['PlaningStatus'],
  endpoints: (builder) => ({
    getAllPlaningStatus: builder.query({
      query: () => 'accountSetup/pregnancyPlanningStatuses',
      transformResponse: (res) => res.data,
      providesTags: ['PlaningStatus'],
    }),
    addNewPlaningStatus: builder.mutation({
      query: (data) => ({
        url: `accountSetup/pregnancyPlanningStatuses`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: data,
      }),
      invalidatesTags: ['PlaningStatus'],
    }),
    updatePlaningStatus: builder.mutation({
      query: ({ id, data }) => ({
        url: `accountSetup/pregnancyPlanningStatuses/${id}`,
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: data,
      }),
      invalidatesTags: ['PlaningStatus'],
    }),

    deletePlaningStatus: builder.mutation({
      query: (id) => ({
        url: `accountSetup/pregnancyPlanningStatuses/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['PlaningStatus'],
    }),
  }),
});

export const {
  useGetAllPlaningStatusQuery,
  useAddNewPlaningStatusMutation,
  useUpdatePlaningStatusMutation,
  useDeletePlaningStatusMutation,
} = planingStatusAPI;
