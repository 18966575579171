import { createApi } from '@reduxjs/toolkit/query/react';
import { createCustomFetchBaseQuery } from '../baseQuery';

export const conceptionMethodApi = createApi({
  reducerPath: 'conceptionMethods',
  baseQuery: createCustomFetchBaseQuery(),
  tagTypes: ['ConceptionMethod'],
  endpoints: (builder) => ({
    getAllConceptionMethod: builder.query({
      query: () => 'accountSetup/conceptionMethods',
      transformResponse: (res) => res.data,
      providesTags: ['ConceptionMethod'],
    }),
    addNewConceptionMethod: builder.mutation({
      query: (data) => ({
        url: `accountSetup/conceptionMethods`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: data,
      }),
      invalidatesTags: ['ConceptionMethod'],
    }),
    updateConceptionMethod: builder.mutation({
      query: ({ id, data }) => ({
        url: `accountSetup/conceptionMethods/${id}`,
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: data,
      }),
      invalidatesTags: ['ConceptionMethod'],
    }),

    deleteConceptionMethod: builder.mutation({
      query: (id) => ({
        url: `accountSetup/conceptionMethods/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['ConceptionMethod'],
    }),
  }),
});

export const {
  useGetAllConceptionMethodQuery,
  useAddNewConceptionMethodMutation,
  useUpdateConceptionMethodMutation,
  useDeleteConceptionMethodMutation,
} = conceptionMethodApi;
